import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Problem statement`}</h1>
    <ul>
      <li parentName="ul">{`Consider of the comment field at the end of a survey, or the comment section at the end of a news article. Both allow participants to write down whatever they think.`}
        <ul parentName="li">
          <li parentName="ul">{`Humans need to process the massive amount of unstructured text that results. This creates a burden on both survey research teams and comment moderators, respectively, as threads spin out of control and text comments sit in a database. It also creates a burden on readers, who have to process complex threads.`}</li>
          <li parentName="ul">{`It is also impossible to discern whether a given viewpoint is representative of the majority or not.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      